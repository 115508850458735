import React from "react";
import PageLayout from "../components/pagelayout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404: Not found" />
    <h1 style={{ margin: 20, fontSize: 18 }}>NOT FOUND</h1>
    <p style={{ marginLeft: 20, marginBottom: 500 }}>
      You just hit a route that doesn&#39;t exist... the sadness
    </p>
  </PageLayout>
);

export default NotFoundPage;
